<style lang="less">
  .vertical .org-chart-node-label .org-chart-node-label-inner {
    writing-mode: vertical-lr;
    height: 164px;
  }
  .horizontal {
    .org-chart-node-label {
      .org-chart-node-label-inner {
        width: 164px;
        padding: 3px;
        margin: 2px 0;
      }
    }
  }
  .vertical .org-chart-node-label .org-chart-node-label-inner, .horizontal .org-chart-node-label .org-chart-node-label-inner {
    border-radius: 0;
    overflow: visible;
    text-overflow: ellipsis;
    word-break: keep-all;
  }
  .vertical .org-chart-node {
    padding: 20px 1px 0 1px;
  }
  .org-chart-node-label-inner {
    position: relative;
    .action {
      display: flex;
      opacity: 0;
      position: absolute;
      top: 50%;
      left: 50%;
      width: 175px;
      transform: translate(-50%, -50%);
      transition: all .3s;
    }
    &:hover {
      .action {
        opacity: 1;
      }
    }
  }
  .vertical .org-chart-node-label-inner {
    position: relative;
    .action {
      display: block;
      opacity: 0;
      width: 80px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      transition: all .3s;
    }
    &:hover {
      .action {
        opacity: 1;
      }
    }
  }
  .blue-theme {
    .org-page {
      .b-active {
        border-color: #2F5BEA;
        color: #2F5BEA;
      }
    }
  }
  .yellow-theme {
    .org-page {
      .b-active {
        border-color: #6C573C;
        color: #6C573C;
      }
    }
  }
  .pink-theme {
    @pink-color: #F4628F;
    @default-color: #7F7F7F;
    .org-page {
      .b-active {
        border-color: rgba(244, 98, 143, 1);
        color: rgba(251, 109, 143, 1);
      }
    }
    .org-chart-node-label-inner {
      // background-color: #F4628F;
      color: @default-color;
      border: 1px solid @default-color;
      &:hover {
        .action {
          .fm-btn:hover {
            background-color: #FFF;
          }
        }
      }
    }
    .horizontal {
      .org-chart-node:not(.is-left-child-node):after {
        border-top-color: @default-color;
        border-left-color: @default-color;
      }
      .org-chart-node:not(.is-left-child-node):before {
        border-left-color: @default-color;
      }
      .org-chart-node-children:before {
        border-top-color: @default-color;
      }
      .org-chart-node:not(.is-left-child-node):not(.is-not-child):last-child:before {
        border-bottom-color: @default-color;
      }
    }
    .vertical {
      .org-chart-node:before {
        border-top-color: @default-color;
      }
      .org-chart-node:after {
        border-left-color: @default-color;
        border-top-color: @default-color;
      }
      .org-chart-node:last-child:before {
        border-right-color: @default-color;
      }
      .org-chart-node-children:before {
        border-left-color: @default-color;
      }
      .org-chart-node-label-inner {
        .action {
          .fm-btn {
            writing-mode: initial;
          }
          .fm-btn + .fm-btn {
            margin-left: 0;
            margin-top: 10px;
          }
        }
      }
    }
    .horizontal, .vertical {
      .org-chart-node-label {
        .org-chart-node-btn {
          border-color: @default-color;
        }
        .org-chart-node-btn:before {
          border-top-color: @default-color;
        }
        .org-chart-node-btn:after {
          border-left-color: @default-color;
        }
      }
    }
  }
</style>

<template>
  <div class="org-page">
    <div class="org-page-div">
      <div class="org-page-content content-block tree-img">
        <div class="content-top">
          <fm-title :title-text="chooseData ? chooseData.name : '单位党组织'">
          </fm-title>
        </div>
        <div class="content-body" v-if="chooseData">
          <org-form
            :source-data="chooseData || {}"
            ref="orgForm">
          </org-form>
        </div>
        <div class="btns" v-if="chooseData">
          <fm-btn @click="formSubmit">保存</fm-btn>
      </div>
      <div v-else class="content-body note">
        无当前党组织
      </div>
      </div>
    </div>
  </div>
</template>

<script>
import OrgForm from './org/dw'

import {
  orgRequest
} from '@/api'

export default {
  components: {
    OrgForm
  },
  data () {
    return {
      loading: false,
      chooseData: null,
      direction: false
    }
  },
  computed: {
    currentOrgId () {
      return this.$store.getters.currentOrgId
    }
  },
  created () {
    this.loadData()
  },
  methods: {
    defaultOptionsFilterMethod (filterValue, columnValue) {
      return filterValue === columnValue
    },
    getData (dataList, data) {
      dataList.filter(v => v.id).forEach((v) => {
        data.push(v)
        if (v.child && v.child.length > 0) {
          this.getData(v.child, data)
        }
      })
    },
    countDataChange (data) {
      this.noteText = '总数:' + data
    },
    dealSData (data) {
      data.forEach((v) => {
        if (v.child && v.child.length > 0 && v.roleList && v.roleList.length > 0) {
          v.child.push({
            name: '',
            roleList: v.roleList
          })
        }
        if (v.child && v.child.length > 0) {
          this.dealSData(v.child)
        }
      })
    },
    async loadData () {
      if (!this.currentOrgId) {
        this.$notify({
          title: '系统提示',
          message: '无当前党组织',
          type: 'info'
        })
        return
      }
      try {
        this.loading = true
        let data = await orgRequest.getTree()
        let deal = (datas) => {
          datas.forEach(v => {
            if (v.roleList) {
              v.roleList.forEach(role => {
                role.orgId = v.id
              })
            }
            if (v.child) {
              deal(v.child)
            }
          })
        }
        deal(data)
        this.chooseData = data.find(v => v.id === this.currentOrgId)
        this.loading = false
      } catch (e) {
        console.log(e)
        this.loading = false
      }
    },
    async formSubmit () {
      let data = this.$refs.orgForm.getFormData()
      if (this.chooseData && this.chooseData.id) {
        await orgRequest.update(this.chooseData.id, data)
        // await orgRequest.updateRole(this.chooseData.id, {
        //   roleIds: data.roleIds && data.roleIds.length > 0 ?  data.roleIds.join(',') : null
        // })
        this.$notify({
          title: '系统提示',
          message: '修改完成',
          type: 'success'
        })
        this.loadData()
        this.openDialog = false
      }
    }
  }
}
</script>

<style lang="less">
.vertical-t {
  .vertical {
    .org-chart-node {
      .org-chart-node-label-inner {
        height: 22px;
        writing-mode: lr;
      }
      .org-chart-node {
        .org-chart-node-label-inner {
          height: 22px;
          writing-mode: lr;
        }
        .org-chart-node {
          .org-chart-node-label-inner {
            height: 164px;
            writing-mode: vertical-lr;
          }
        }
      }
    }
  }
}
</style>

<style scoped lang="less">
@import '../../styles/values.less';
.btns {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
}
.b-r {
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  padding: 0 24px;
  border: 1px solid rgba(217, 217, 217, 1);
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}
.b-l {
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  border: 1px solid rgba(217, 217, 217, 1);
  padding: 0 24px;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}
.is-tree-check {
  height: 100%;
  display: flex;
  align-items: center;
}
.org-table-th {
  background: rgba(250, 250, 250, 1);
  height: 32px;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.85);
}
.org-table-td {
  height: 32px;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.85);
}
.content-body-tb {
  border-left: 1px solid #dcdee2;
  border-top: 1px solid #dcdee2;
  margin-left: 25px;
  margin-top: 10px;
  margin-bottom: 25px;
  width: 100%;
}
.org-table-cell {
  border-right: 1px solid #dcdee2;
  padding: 0 25px;
  border-bottom: 1px solid #dcdee2;
  .cell-item {
    position: relative;
    min-height: 32px;
    display: flex;
    align-items: center;
    .cell-item-label {
      font-size: 12px;
      color: rgba(0, 0, 0, 0.85);
      font-weight: 400;
    }
    .action {
      position: absolute;
      top: 26px;
      left: 0;
      display: none;
      opacity: 1;
      z-index: 2;
      background-color: #FFF;
      transition: all .3s;
    }
    .action-active {
      display: flex;
      opacity: 1;
    }
  }
}
.content-bottom {
  height: 4.3rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.org-page-div {
  border-radius: 8px;
  margin: 20px;
  display: flex;
  flex-direction: column;
  flex: 1;
}
.org-page-div-no-padding {
  margin: 0;
}
.org-page {
  width: 100%;
  height: 100%;
  display: flex;
  .fm-table-head-cells {
    background-color: #FFF;
  }
}
.org-page-top-1 {
  margin-bottom: 1rem;
}
.org-page-top-2 {
  margin-bottom: 1.25rem;
}
.org-page-content {
  flex: 1;
  height: 100%;
}
.content-body-tree {
  overflow: auto;
  position: relative;
}
.tree-img {
  background-image: url(/static/images/work/1.png);
  background-size: 339px;
  background-position: bottom right;
  background-repeat: no-repeat;
}
.note {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
}
.content-body {
  height: calc(100% - 88px);
  overflow-y: auto;
  padding: 10px 0;
  position: relative;
  width: 100%;
}
</style>
